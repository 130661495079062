import React from 'react';
import styled from 'styled-components';
import Button from './button';
import { G_CODE, TESTIMONIAL_BUTTON_IDENTIFIER, SPOKEO_SEARCH_URL, CLICK_BUTTON_FUNCTION } from '../utils/constants';
import device from '../utils/devices';

const TestimonialWrapper = styled.div`
  background-color: #f5f5f5;
  text-align: center;
  padding: 75px 15px;
`;

const TestimonialList = styled.div`
  display: flex;
  margin: 0 0 50px 0;
  flex-direction: column;
  @media ${ device.tablet } {
    flex-direction: row;
  }
`;
const Testimonial = styled.div`
  padding: 40px 0;
  max-width: 450px;
  margin: auto;
  border-bottom: 1px solid rgba(25, 25, 25, 0.25);
  &:last-child {
    border-bottom: 0;
  }

  @media ${ device.tablet } {
    border-bottom: 0;
    padding: 0 20px;
    border-right: 1px solid rgba(25, 25, 25, 0.25);
    &:last-child {
      border-right: 0;
    }
  }
`;
const Statement = styled.div`
  font-style: italic;
`;
const User = styled.div`
  color: rgba(26, 26, 26, .6);
  margin: 0 0 20px 0;
`;

const Name = styled.div`
  font-size: 25px;
`;

const Location = styled.div`
  font-size: 14px;
`;

const TestimonialButton = styled(Button)`
  background-color: #333;
  border-radius: 0;
`;

const Testimonials = () => (
  <TestimonialWrapper id="testimonials">
    <TestimonialList>
      <Testimonial>
        <User>
          <Name>Shannon, 26</Name>
          <Location>Clarksville, TN.</Location>
        </User>
        <Statement>
          I dodged a bullet! This guy I was about to go on a first date with turned out to have a criminal record.
        </Statement>
      </Testimonial>
      <Testimonial>
        <User>
          <Name>Maria, 41</Name>
          <Location>Colorado Springs, CO.</Location>
        </User>
        <Statement>
          Thanks for helping me find out who was texting my 17 year old. Fast Phone Lookup gave me some clarity.
        </Statement>
      </Testimonial>
      <Testimonial>
        <User>
          <Name>Shaun, 32</Name>
          <Location>Lincoln, ME.</Location>
        </User>
        <Statement>
          I needed more information on this guy who hit my car! I just did a reverse phone lookup and got his address.
        </Statement>
      </Testimonial>
    </TestimonialList>
    <TestimonialButton
      btnFunction={CLICK_BUTTON_FUNCTION}
      identifier={TESTIMONIAL_BUTTON_IDENTIFIER}
      url={`${ SPOKEO_SEARCH_URL }?g=${ G_CODE }&q=`}
      value="Reverse Phone Lookup"
    />
  </TestimonialWrapper>
);

export default Testimonials;
