import React from 'react';
import styled from 'styled-components';
import faqOneImage from '../images/phone-on-lap.jpg';
import faqTwoImage from '../images/bag-electronics-phone.jpg';
import Button from './button';
import { G_CODE, FAQ_1_BUTTON_IDENTIFIER, FAQ_2_BUTTON_IDENTIFIER, SPOKEO_SEARCH_URL, CLICK_BUTTON_FUNCTION } from '../utils/constants';
import device from '../utils/devices';

const FaqWrapper = styled.section`
  max-width: 1024px;
  margin: auto;
`;

const FaqRow = styled.div`
  display: flex;
  margin: 20px 0;
  flex-direction: column;
  @media ${ device.tablet } {
    min-height: 350px;
    margin: 100px 0;
    flex-direction: row;
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
`;

const FaqImage = styled.div`
  background-image: url(${ ({ backgroundImage }) => backgroundImage });
  background-size: cover;
  background-position: center;
  margin: 0 10px;
  min-height: 300px;
  @media ${ device.mobileL } {
    margin: 0 30px;
  }
  @media ${ device.tablet } {
    flex: 1;
    flex-basis: 50%;
  }
`;

const FaqText = styled.div`
  text-align: center;
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${ device.tablet } {
    padding: 0 50px;
    flex: 1;
    flex-basis: 50%;
    justify-content: center;
  }
`;

const Question = styled.h3`
  color: rgba(26, 26, 26, 0.5);
  font-style: italic;
  font-weight: 400;
`;

const Answer = styled.p``;

const Faq = () => (
  <FaqWrapper id="faq">
    <FaqRow>
      <FaqImage backgroundImage={faqOneImage} />
      <FaqText>
        <Question>What information will I find?</Question>
        <Answer>
          Our phone search results include names, emails, addresses, criminal records, & even social media information!
        </Answer>
        <Button
          btnFunction={CLICK_BUTTON_FUNCTION}
          identifier={FAQ_1_BUTTON_IDENTIFIER}
          url={`${ SPOKEO_SEARCH_URL }?g=${ G_CODE }&q=`}
          value="Search Now"
        />
      </FaqText>
    </FaqRow>
    <FaqRow>
      <FaqImage backgroundImage={faqTwoImage} />
      <FaqText>
        <Question>Are searches confidential?</Question>
        <Answer>
          Absolutely! Searches are completely confidential. The people you search will never know. So search away!
        </Answer>
        <Button
          btnFunction={CLICK_BUTTON_FUNCTION}
          identifier={FAQ_2_BUTTON_IDENTIFIER}
          url={`${ SPOKEO_SEARCH_URL }?g=${ G_CODE }&q=`}
          value="Phone Lookup"
        />
      </FaqText>
    </FaqRow>
  </FaqWrapper>
);

export default Faq;
