import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { SPOKEO_SEARCH_URL, G_CODE, SEARCH_BUTTON_FUNCTION } from '../utils/constants';
import { triggerSearch } from '../utils/fb-pixel';
import device from '../utils/devices';
import Button from './button';

const SearchFormContainer = styled.div`
  margin: auto;
  max-width: 400px;
  text-align: center;

  @media ${ device.mobileL } {
    display: flex;
    justify-content: center;
  }
`;

const TelInput = styled.input`
  border-radius: 10px;
  margin: 10px 0;
  padding: 0 10px;
  font-size: 38px;
  text-align: center;
  flex: 3;
  width: 100%;

  @media ${ device.mobileL } {
    margin: 0;
    padding: 5px 20px;
    font-size: 30px;
    border: none;
    border-radius: 5px 0 0 5px;
  }
`;

const SubmitButton = styled(Button)`
  border-radius: 10px;
  width: 100%;
  padding: inherit;
  font-size: 38px;
  @media ${ device.mobileL } {
    border-radius: 0 5px 5px 0;
    flex: 1;
    font-size: 25px;
    padding: 5px 15px;
  }
`;

class SearchForm extends PureComponent {
  constructor (props) {
    super(props);
    this.state = { searchValue: '' };
  };

  updateSearchValue = e => {
    this.setState({ searchValue: e.target.value });
  }

  render () {
    return (
      <SearchFormContainer>
        <TelInput onChange={this.updateSearchValue} type="tel" size="15" name="q" placeholder="(931) 867-5309" />
        <input type="hidden" name="g" value={ G_CODE } />
        <SubmitButton
          url={`${ SPOKEO_SEARCH_URL }?g=${ G_CODE }&q=${ this.state.searchValue }`}
          btnFunction={SEARCH_BUTTON_FUNCTION}
          value="Search"
        />
      </SearchFormContainer>
    );
  }
};

export default SearchForm;
