const triggerButtonClick = buttonId => {
  if (window.fbq) {
    window.fbq('track', 'InitiateCheckout', {
      value: buttonId,
    });
  }
};

const triggerSearch = query => {
  if (window.fbq) {
    window.fbq('track', 'Search', {
      search_string: query,
    });
  }
};

module.exports = {
  triggerSearch,
  triggerButtonClick
}