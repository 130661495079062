import React, { Component } from 'react';
import styled from 'styled-components';
import NavButton from './nav-button';
import { SPOKEO_SEARCH_URL, MAIN_BUTTON_IDENTIFIER, G_CODE } from '../utils/constants';
import device from '../utils/devices';
import closeIcon from '../images/close.svg';
import hamburgerIcon from '../images/hamburger.svg';

const NavBarContainer = styled.div`
  position: fixed;
  z-index: 99;
  background-color: #fff;
  top: 0;
  right: 0;
  align-items: center;
  padding: 15px 40px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 800;
  font-style: italic;
  @media ${ device.mobileL } {
    background-color: inherit;
    position: absolute;
    margin: 30px 15px;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1px;
    font-weight: 600;
    white-space: nowrap;
    align-items: center;
    width: fit-content;
    flex-direction: row-reverse;
    padding: 0;
  }
  @media ${ device.tablet } {
    letter-spacing: 2px;
  }
`;

const NavButtons = styled.div`
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
  left: 0;
  ${ props => {
    if (props.isMobileOpen) {
      return `
    display: flex;
    position: fixed;
    top: 75px;
    background-color: #fff;
    padding: 0;
    `;
    }
    return '';
  } }
  @media ${ device.mobileL } {
    background-color: inherit;
    display: flex;
    position: relative;
    top: 0;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
`;

const ToggleButton = styled.div`
  display: block;
  height: 100%;
  width: fit-content;

  &:hover {
    cursor: pointer;
  }
  @media ${ device.mobileL } {
    display: none;
  }
`;

const ToggleIcon = styled.img`
  width: 32px;
  margin: inherit;
`;

class NavBar extends Component {
  constructor (props) {
    super(props);
    this.state = { isMobileOpen: false };
  }

  toggleIconUrl = () => (this.state.isMobileOpen ? closeIcon : hamburgerIcon);

  toggleMobileNav = () => this.setState(prevState => ({ isMobileOpen: !prevState.isMobileOpen }))

  render () {
    return (
      <NavBarContainer>
        <ToggleButton onClick={this.toggleMobileNav}>
          <ToggleIcon src={this.toggleIconUrl()} />
        </ToggleButton>
        <NavButton
          url={`${ SPOKEO_SEARCH_URL }?g=${ G_CODE }&q=`}
          text="Search Now"
          identifier={MAIN_BUTTON_IDENTIFIER}
        />
        <NavButtons onClick={this.toggleMobileNav} { ...this.state }>
          <NavButton url="#about" text="About" />
          <NavButton url="#faq" text="FAQ" />
          <NavButton url="#testimonials" text="Our Customers" />
        </NavButtons>
      </NavBarContainer>
    );
  }
};

export default NavBar;
