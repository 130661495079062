import React from 'react';
import styled from 'styled-components';
import device from '../utils/devices';

const AboutWrapper = styled.section`
  max-width: 956px;
  padding: 50px 25px;
  text-align: center;
  margin: auto;

  @media ${ device.mobileL } {
    padding: 75px 0 25px 0;
  }
`;
const Title = styled.h2`
  font-weight: 400;
  font-size: 32px;
  width: 100%;
  color: rgba(25, 25, 25, 0.85);
`;
const Paragraph = styled.p`
  max-width: 600px;
  margin: auto;
  font-size: 18px;
  color: rgba(25, 25, 25, 0.75);
`;

const About = () => (
  <AboutWrapper id="about">
    <Title>Reverse phone lookups are quick, easy, and confidential.</Title>
    <Paragraph>
      Looking up a phone number is quick & easy. We search through billions of phone records & even have social media 
      data to help you uncover even more information! Remember, all searches are confidential.
    </Paragraph>
  </AboutWrapper>
);

export default About;
