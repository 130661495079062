import React from 'react';
import styled from 'styled-components';
import device from '../utils/devices';

const LogoContainer = styled.div`
  display: none;

  @media ${ device.tablet } {
    display: block;
    width: 150px;
    color: #fff;
    margin: 15px;
    text-align: center;
  }
`;

const TopBar = styled.div`
  font-family: 'Georgia';
  font-size: 60px;
  line-height: 60px;
  letter-spacing: 3px;
`;

const BottomBar = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const Logo = () => (
  <LogoContainer>
    <TopBar>Fast</TopBar>
    <BottomBar>Phone Lookup</BottomBar>
  </LogoContainer>
);

export default Logo;
