import React from 'react';
import styled from 'styled-components';
import device from '../utils/devices';
import { triggerButtonClick, triggerSearch } from '../utils/fb-pixel';
import { CLICK_BUTTON_FUNCTION, SEARCH_BUTTON_FUNCTION } from '../utils/constants';

const ButtonWrapper = styled.button`
  border-radius: 50px;
  cursor: pointer;
  color: #fff;
  background-color: rgba(54, 179, 168, 1);
  font-weight: 600;
  margin: 10px 0;
  font-size: 20px;
  border: none;
  width: fit-content;
  padding: 5px 30px;

  @media ${ device.mobileL } {
    margin: 0;
    transition: background-color 0.3s ease;
    font-size: 16px;
    padding: 10px 30px;
    width: auto;
  
    &:hover {
      background-color: rgba(54, 179, 168, 0.75);
    }
  }
`;

const onClick = (e, url, identifier, btnFunction = CLICK_BUTTON_FUNCTION) => {
  e.preventDefault();
  if (btnFunction === CLICK_BUTTON_FUNCTION) {
    triggerButtonClick(url, identifier);
  } else if (btnFunction === SEARCH_BUTTON_FUNCTION) {
    triggerSearch(url, identifier);
  }
  window.location.href = url;
};

const Button = ({ value, className, url, identifier, btnFunction }) => (
  <ButtonWrapper onClick={e => onClick(e, url, identifier, btnFunction)} className={ className } value={value}>
    {value}
  </ButtonWrapper>
);

export default Button;
