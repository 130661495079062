import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import About from '../components/about';
import Faq from '../components/faq';
import Testimonials from '../components/testimonials';
import Footer from '../components/footer';

const IndexPage = () => (
  <Layout>
    <SEO title="Find out who's calling!" keywords={[`reverse`, `phone`, `lookup`]} />
    <Hero />
    <About />
    <Faq />
    <Testimonials />
    <Footer />
  </Layout>
);

export default IndexPage;
