import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { SPOKEO_SEARCH_URL, G_CODE, FOOTER_BUTTON_IDENTIFIER, CLICK_BUTTON_FUNCTION } from '../utils/constants';
import Button from './button';

const FooterContainer = styled.footer`
  background-color: rgba(25, 25, 25, 0.95);
  width: 100%;
  text-align: center;
`;

const SquareButton = styled(Button)`
  border-radius: 0;
`;

const Statement = styled.div`
  font-size: 28px;
  color: #fff;
  padding: 50px;
`;

const Copyright = styled.div`
  font-style: italic;
  font-size: 11px;
  color #fff;
  padding: 50px 0 25px 0;
`;

class Footer extends PureComponent {
  render () {
    return (
      <FooterContainer>
        <Statement>
          You deserve to know who is contacting you. Knowledge is power.
        </Statement>
        <SquareButton
          btnFunction={CLICK_BUTTON_FUNCTION}
          identifier={FOOTER_BUTTON_IDENTIFIER}
          url={`${ SPOKEO_SEARCH_URL }?g=${ G_CODE }&q=`}
          value="Know Who Is Calling"
        />
        <Copyright>
          Copyright © 2019 Fast Phone Lookup
        </Copyright>
      </FooterContainer>
    );
  }
}

export default Footer;
