import React from 'react';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import device from '../utils/devices';
import { triggerButtonClick } from '../utils/fb-pixel';
import { MAIN_BUTTON_IDENTIFIER } from '../utils/constants';

const BASE_BUTTON_LINK_STYLE = `
  color: #000;
  border-bottom: 1px solid rgba(25, 25, 25, 0.75);
  width: 100%;
  padding: 20px;
  background: #fff;
  text-decoration: none;
  margin: 0;
  &:hover {
    background: rgba(54, 179, 168, 1);
    color: #fff;
    border-bottom: 1px solid #000;
  }
`;

const BASE_DESKTOP_BUTTON_LINK_STYLE = `
  margin: 0 10px;
  color: #fff;
  text-transform: uppercase;
  background-color: inherit;
  padding: 0;
  border: none;
  &:first-of-type {
    border-top: none;
  }
  &:hover {
    background-color: inherit;
    border-bottom: 1px solid rgba(54, 179, 168, 1);
  }
`;

const getButtonLink = identifier => {
  if (identifier === MAIN_BUTTON_IDENTIFIER) {
    return styled.a`
      ${ BASE_BUTTON_LINK_STYLE }
      width: fit-content;
      border-bottom: inherit;
      border-top: inherit;
      border: 2px solid rgba(54, 179, 168, 1);
      border-radius: 30px;
      padding: 5px 20px;
      background-color: rgb(255, 255, 255);
      color: #000;
      transition: background-color 0.25s ease, color 0.25s ease;

      &:hover {
        background-color: rgba(54, 179, 168, 1);
        color: #fff;
        border-bottom: 2px solid rgba(54, 179, 168, 1);
      }
      @media ${ device.mobileL } {
        ${ BASE_DESKTOP_BUTTON_LINK_STYLE }
        padding: 10px 20px;
        border: 2px solid #fff;
        &:first-of-type {
          border-top: 2px solid #fff;
        }
        background-color: rgba(255, 255, 255, 0);
        color: #fff;

        &:hover {
          background-color: rgba(255, 255, 255, 1);
          color: #000;
          border: 2px solid #fff;
        }
      }
      @media ${ device.tablet } {
        margin: 0 15px;
      }
    `;
  }
  return styled(AnchorLink)`
    ${ BASE_BUTTON_LINK_STYLE }
    &:first-of-type {
      border-top: 1px solid rgba(25, 25, 25, 0.75);
    }
    @media ${ device.mobileL } {
      ${ BASE_DESKTOP_BUTTON_LINK_STYLE }
    @media ${ device.tablet } {
      margin: 0 15px;
    }
  `;
};

const onClick = (e, url, identifier) => {
  e.preventDefault();
  triggerButtonClick(url, identifier);
  window.location.href = url;
};

const NavButton = ({ url, text, identifier }) => {
  const ButtonLink = getButtonLink(identifier);
  return (
    <ButtonLink onClick={e => onClick(e, url, identifier)} identifier={identifier} href={url}>
      {text}
    </ButtonLink>
  );
};

export default NavButton;
