import React from 'react';
import styled from 'styled-components';
import heroImage from '../images/adult-with-phone.jpg';
import SearchForm from './search-form';
import Logo from './logo';
import device from '../utils/devices';
import NavBar from '../components/nav-bar';

const HeroContainer = styled.div`
  position: absolute;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${ heroImage }");

  /* Set a specific height */
  height: 90vh;
  min-height: 500px;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
`;

const Titles = styled.div`
  margin: 0 0 65px 0;

  @media ${ device.mobileL } {
    margin: 0 0 45px 0;
  }
`;

const Title = styled.h1`
  color: #fdfdfd;
  font-size: 50px;
  margin: 0 0 20px 0;
  font-weight: 800;

  @media ${ device.mobileL } {
    font-size: 60px;
    margin: 0 0 10px 0;
  }
`;

const Subtitle = styled.h2`
  color: #fdfdfd;
  font-size: 25px;
  font-weight: 400;
`;

const CenterContent = styled.div`
  max-width: 90%;
  width: fit-content;
  border-radius: 5px;
  height: fit-content;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  text-align: center;
`;

const Hero = () => (
  <HeroContainer>
    <Logo />
    <NavBar />
    <CenterContent>
      <Titles>
        <Title>Find who's calling!</Title>
        <Subtitle>Search any phone number fast and easy.</Subtitle>
      </Titles>
      <SearchForm />
    </CenterContent>
  </HeroContainer>
);

export default Hero;
