import React from 'react';
import styled from 'styled-components';

const ScriptsContainer = styled.div`
  display: none;
`;

const affiliatesUrl = 'https://www.spokeoaffiliates.com';

const ScriptInjections = () => (
  <ScriptsContainer>
    <script
      defer
      async
      src={`${ affiliatesUrl }/v2/banners/get-phone-banner.js?type=whos_texting_your_child&v=101&ftype=phone`}
      type='text/javascript'
    />
    <span
      className="banner_container"
      data-affiliate-key='A6253617252'
      id='whos_texting_your_child_v101'
    />

    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-132772898-1"></script>
    <script dangerouslySetInnerHTML={{ __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'UA-132772898-1');
    ` }} />
    <noscript>
      <iframe
        alt='Spokeo Phone Lookup'
        frameborder='0'
        height='250'
        scrolling='auto'
        src={`${ affiliatesUrl }/v2/banners/phone?type=whos_texting_your_child&v=101&ftype=phone`}
        width='250'
      />
    </noscript>
    <script dangerouslySetInnerHTML={{ __html: `
      window.addEventListener('load', function() {
        delete window.document.referrer;
        window.document.__defineGetter__('referrer', function () {
            return "www.spokeoaffiliates.com/v2/banners/phone";
        });
      });
    ` }}
    />
    <script dangerouslySetInnerHTML={{ __html: `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '541141056365748');
      fbq('track', 'PageView');
    ` }}>
    </script>
    <noscript><img height="1" width="1" style={{ display: 'none' }}
      src="https://www.facebook.com/tr?id=541141056365748&ev=PageView&noscript=1"
    /></noscript>
  </ScriptsContainer>
);

export default ScriptInjections;
