export const SPOKEO_BASE_URL = '//spokeo.com';
export const SPOKEO_SEARCH_URL = `${ SPOKEO_BASE_URL }/search`;
export const G_CODE = 'phone_whos-texting-your-child_v101_A6253617252';
export const MAIN_BUTTON_IDENTIFIER = 'main';
export const FAQ_1_BUTTON_IDENTIFIER = 'faq-1-btn';
export const FAQ_2_BUTTON_IDENTIFIER = 'faq-2-btn';
export const FOOTER_BUTTON_IDENTIFIER = 'footer-btn';
export const TESTIMONIAL_BUTTON_IDENTIFIER = 'testimonial-btn';
export const CLICK_BUTTON_FUNCTION = 'click';
export const SEARCH_BUTTON_FUNCTION = 'search';
export const NAVIGATE_BUTTON_FUNCTION = 'navigate';
